import Link from "next/link";
import Logo from "@/components/icon/logo";

export function ScrollTo() {
  return (
    <div className="scroll-to">
      <Link href="/">
        <Logo />
      </Link>
    </div>
  );
}

export default ScrollTo;
