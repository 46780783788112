/* eslint-disable import/no-anonymous-default-export */
import { STRAPI_URL } from "@Environment*";
import { Project, RelatedLinks, Policy, Members } from "./model/strapi.model";
import qs from "qs";
import exp from "constants";

export const getCover = (cover: any) => {
  const url = `${STRAPI_URL}${cover}`;
  return url;
};

export const getMembers = async () => {
  const queryMembers = qs.stringify(
    { populate: "*" },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  const res = await fetch(`${STRAPI_URL}/api/teams?${queryMembers}`);

  if (!res.ok) {
    throw new Error("Something went wrong");
  }

  const { data } = (await res.json()) as Members;

  return data.map(({ attributes, id }) => {
    const { name, role, location, experiences, languages, uid } = attributes;
    return { attributes, id };
  });
};

export const getProjects = async () => {
  const queryProjects = qs.stringify(
    {
      populate: {
        cover: true,
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  const res = await fetch(`${STRAPI_URL}/api/projects?${queryProjects}`);

  if (!res.ok) {
    throw new Error("Something went wrong");
  }

  const { data } = (await res.json()) as Project;

  return data.map(({ attributes, id }) => {
    const { title, slug, outstanding } = attributes;
    const { url: cover } = attributes.cover.data.attributes.formats.large;
    return { id, title, slug, cover, outstanding };
  });
};

export const getPolicy = async () => {
  const queryPolicy = qs.stringify(
    {
      populate: "*",
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  const res = await fetch(`${STRAPI_URL}/api/policies?${queryPolicy}`);

  if (!res.ok) {
    throw new Error("Something went wrong");
  }

  const { data } = (await res.json()) as Policy;

  return data.map(({ attributes, id }) => {
    const { title, rulesText } = attributes;
    return { id, title, rulesText };
  });
};

// export const getProjectByName = async (id: string) => {
//   const res = await fetch(
//     `${STRAPI_URL}/api/projects?filters[title][$contains]=${id}&populate=*`
//   );

//   if (!res.ok) {
//     throw new Error("Something went wrong");
//   }

//   const { data } = await res.json();

//   const allProjects = await getProjects(); // Obtén todos los proyectos

//   const currentIndex = allProjects.findIndex(
//     (project) => project.id === parseInt(id)
//   );

//   if (currentIndex === -1) {
//     throw new Error(`Project with ID ${id} not found`);
//   }

//   let prevIndex = currentIndex - 1;
//   let nextIndex = currentIndex + 1;

//   //   Ajusta los índices para manejar los extremos de la lista de proyectos
//   if (prevIndex < 0) {
//     prevIndex = allProjects.length - 1;
//   }

//   if (nextIndex >= allProjects.length) {
//     nextIndex = 0;
//   }

//   const { attributes } = data as Project["data"][0];
//   const {
//     title,
//     slug,
//     short_description,
//     extend_description,
//     service,
//     layout,
//     images,
//   } = attributes;

//   const project = {
//     title,
//     slug,
//     short_description,
//     extend_description,
//     service,
//     images,
//     layout,
//   };

//   const prevProject = allProjects[prevIndex];
//   const nextProject = allProjects[nextIndex];

//   return {
//     project,
//     prevProject,
//     nextProject,
//   };
// };

export const getProjectByName = async (slug: string) => {
  const res = await fetch(
    `${STRAPI_URL}/api/projects?filters[title][$contains]=${slug}&populate=*`
  );

  if (!res.ok) {
    throw new Error("Something went wrong");
  }

  const { data } = await res.json();

  if (!data || data.length === 0) {
    throw new Error(`Project with slug ${slug} not found`);
  }

  const currentProject = data[0];

  const allProjects = await getProjects(); // Obtén todos los proyectos

  const currentIndex = allProjects.findIndex(
    (project) => project.slug === slug
  );

  let prevIndex = currentIndex - 1;
  let nextIndex = currentIndex + 1;

  // Ajusta los índices para manejar los extremos de la lista de proyectos
  if (prevIndex < 0) {
    prevIndex = allProjects.length - 1;
  }

  if (nextIndex >= allProjects.length) {
    nextIndex = 0;
  }

  const {
    title,
    short_description,
    extend_description,
    service,
    layout,
    images,
  } = currentProject.attributes;

  const project = {
    title,
    slug,
    short_description,
    extend_description,
    service,
    images,
    layout,
  };

  const prevProject = allProjects[prevIndex];
  const nextProject = allProjects[nextIndex];

  return {
    project,
    prevProject,
    nextProject,
  };
};

export const getNavigation = async () => {
  const queryNavigation = qs.stringify(
    { populate: "*" },
    { encodeValuesOnly: true }
  );

  const res = await fetch(`${STRAPI_URL}/api/related-links?${queryNavigation}`);

  if (!res.ok) {
    throw new Error("Something went wrong");
  }

  const { data } = (await res.json()) as RelatedLinks;

  // Map para obtener solo las propiedades necesarias (id, name, links)
  const transformedData = data.map((item: any) => {
    const { id, attributes } = item;
    const { links, name } = attributes;

    return {
      id,
      name,
      links: links ? links.links || [] : [],
    };
  });

  return transformedData;
};

export const postSubscription = async (email: string) => {
  try {
    const data = {
      data: {
        email: email,
      },
    };

    const response = await fetch(`${STRAPI_URL}/api/subscribers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      // Manejar errores aquí, si es necesario
      throw new Error(`Error en la petición: ${response.statusText}`);
    }

    const responseData = await response.json();
    //   Puedes hacer algo con la respuesta de la API si es necesario

    return responseData;
  } catch (error) {
    //   Manejar errores generales aquí
    console.error("Error en la función postSubscription:", error);
    throw error;
  }
};

export default {
  getProjects,
  getProjectByName,
  getMembers,
  getNavigation,
  getCover,
  getPolicy,
  postSubscription,
};
