"use client";
import { useState, useEffect } from "react";
import Logo from "./icon/logo"; // Importa el logo si es necesario
import Link from "next/link";

interface HeaderProps {
  title?: string;
  logo?: JSX.Element; // Define el prop logo como opcional
}

const Header: React.FC<HeaderProps> = ({ title = "", logo = <Logo /> }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const scrollThreshold = 50; // Cambia este valor según tus necesidades

      if (scrollTop > scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`header ${isScrolled ? "scrolled" : ""}`}>
      <div className="header__nav">
        <div className={`header__logo ${title ? "hidden" : ""}`}>
          <div className="logo">
            <Link href="/">{logo}</Link>
          </div>
          <h2>{title}</h2>
        </div>
        <div className={`header__navigation ${isMenuOpen ? "open" : ""}`}>
          <Link href="/">Home</Link>
          <Link href="/the-team">The Team</Link>
          <Link href="/construction">Projects</Link>
          <Link href="/construction">Services</Link>
          <Link href="/construction">About Us</Link>
          <Link href="/construction">Contact</Link>
        </div>
        <div className="header__burger" onClick={toggleMenu}>
          <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
          <div className={`line ${isMenuOpen ? "open" : ""}`}></div>
        </div>
      </div>
      <hr className="header__line" />
    </header>
  );
};

export default Header;
