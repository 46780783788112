"use client";
import { postSubscription } from "@/pages/api/strapi";
import React, { useState } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewsletterModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Aquí puedes realizar alguna acción con el correo electrónico, como enviarlo al servidor.
    await postSubscription(email);
    // Luego, cierra el modal.
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button className="close-button" onClick={onClose}>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                width="35px"
                height="35px"
              >
                <rect width="24" height="24" fill="0000"></rect>
                <path
                  d="M7 17L16.8995 7.10051"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="currentColor"
                ></path>
                <path
                  d="M7 7.00001L16.8995 16.8995"
                  stroke="#000000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
            <div className="background__image">
              <img
                src="https://w.forfun.com/fetch/75/7577cf2e29769a7fc2d7962b294316cf.jpeg"
                alt=""
              />
            </div>
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">
                nombre
                <input type="text" name="name" id="name" />
              </label>
              <label htmlFor="email">
                correo
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label htmlFor="email">
                asunto
                <input type="text" name="matter" id="matter" />
              </label>
              <label htmlFor="message">
                mensaje
                <textarea name="message" id="message"></textarea>
              </label>
              <button type="submit">send</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default NewsletterModal;
