"use client";
import Link from "next/link";
import { useRef, useEffect, useState } from "react";
import { AnimationSeeMore } from "@/animation/animation";

interface SeeMoreProps {
  children: React.ReactNode;
  name: string;
  discover: string;
  id: string;
  link?: string;
  additionalAnimation?: any;
  height?: string | any;
}

export default function SeeMore({
  children,
  name,
  discover,
  id,
  link,
  additionalAnimation,
  height,
}: SeeMoreProps) {
  const [isActive, setIsActive] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const elementAnimated = useRef<HTMLDivElement>(null);
  const animation = AnimationSeeMore(); // Obtener las animaciones

  useEffect(() => {
    const containerElement = buttonRef.current;
    if (containerElement) {
      containerElement.classList.remove("active");
    }
  }, []);

  function handleClickOpen() {
    setIsActive((prevState) => !prevState);
    const containerElement = elementAnimated.current;
    if (containerElement) {
      if (isActive) {
        additionalAnimation()
          .closeAnimation.play()
          .add(() => {
            animation.minimizeModel(containerElement).play();
          }, "<+0.4"); //
      } else {
        animation
          .extendModel(containerElement, height)
          .play()
          .add(() => {
            additionalAnimation().openAnimation.play();
          }, "<");
      }
    }
  }

  return (
    <div
      className={`seeMore ${isActive ? "active" : ""}`}
      ref={buttonRef}
      id={id}
    >
      <div className="seeMore__top">
        {name && (
          <h3 onClick={handleClickOpen}>
            {`${isActive ? "- " : "+ "}` + name}
          </h3>
        )}
        {discover && <Link href={`/${link}`}>{`${discover} >`}</Link>}
        <hr className="lines" />
      </div>
      <div
        ref={elementAnimated}
        className={`seeMore__block ${isActive ? "active" : ""}`}
      >
        {children}
      </div>
    </div>
  );
}
