import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Observer } from "gsap/Observer";
import { Flip } from "gsap/Flip";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Flip, Observer);

export enum SectionName {
  Newest = "NEWEST PROJECTS",
  Services = "OUR SERVICES",
  Store = "OUR STORE",
  Footer = "ABOUT US",
  Team = "THE TEAM",
  Projects = "MORE PROJECTS",
  Social = "SOCIAL NETWORKS",
}

gsap.config({
  nullTargetWarn: false,
  force3D: true,
});

export const defaultGSAP = { paused: true, ease: "power3.inOut" };

export function splitText(str: any) {
  const split = new SplitType(str, {
    types: "words, lines" as any,
  });

  return split;
}

export function headerChange(str: string) {
  const tl = gsap.timeline();
  const header = document.querySelector(
    ".header .header__logo h2"
  ) as HTMLElement;

  tl.set(".header .header__logo h2", { overflow: "hidden" });

  tl.to(header, {
    opacity: 0,
    duration: 0.2,
    ease: "power3.inOut",
    onComplete: () => {
      header.innerHTML = str;
      tl.to(header, {
        duration: 0.2,
        opacity: 1,
        ease: "power3.inOut",
      });
    },
  });
}
