interface SocialMedia {
  name: string;
  url: string;
}

export const socialMediaInfo: SocialMedia[] = [
  {
    name: "Behance",
    url: "https://www.behance.net/duboiselisa",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/wildestwilddigital/",
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/wildestwild/",
  },
];

export default socialMediaInfo;
