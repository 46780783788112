/* eslint-disable react/no-children-prop */
import SeeMore from "@/components/layout/SeeMore";

const MobileFooter: React.FC = () => {
  return (
    <div className="footerMobile">
      <div className="footer__info">
        <p>
          We're a digital creative agency that's all about brand strategy, art
          direction, design, and making cool stuff across all platforms, we want
          you to be 100% online!
        </p>
      </div>

      <SeeMore
        name="JOIN OUR NEWSLETTER"
        discover=""
        link=""
        id=""
        children={undefined}
      />
    </div>
  );
};

export default MobileFooter;
