"use client";
/* eslint-disable import/no-anonymous-default-export */
import { gsap } from "gsap";
import { Flip } from "gsap/Flip";
import { splitText } from "./module";
import { time } from "console";

gsap.registerPlugin(Flip);

/*  LOADER & LOADING ANIMATION GSAP */

export function LoadingAnimationFLIP() {
  const tl = gsap.timeline({ paused: true });

  tl.set(".header", {
    className: "header loader",
  });

  tl.set(".header__navigation", {
    position: "absolute",
  });

  tl.set([".container", ".header__line", ".header__navigation"], {
    visibility: "hidden",
  });

  tl.set("body", {
    overflow: "hidden",
    pointerEvents: "none",
    userSelect: "none",
  });

  tl.set(
    [".header__logo #logo #Wild path", ".header__logo #logo #Wildest path"],
    {
      opacity: "0",
    }
  );

  tl.set(["#logo #Punto"], { cx: "560" });

  tl.to("#logo #Punto", {
    r: 25,
    duration: 0.5,
    ease: "power3.inOut",
  });

  tl.to("#logo #Punto", {
    r: 16.83,
    duration: 0.5,
    ease: "power3.inOut",
  });

  tl.to(".header__logo #logo #Wildest path", {
    opacity: 1,
    stagger: 0.08,
    duration: 0.2,
    reversed: true,
    y: 2,
  });

  tl.to(
    ".header__logo #logo #Wild path",
    {
      opacity: 1,
      stagger: 0.08,
      duration: 0.2,
      y: 2,
    },
    ">"
  );

  return tl;
}

export function LoaderAnimationFLIP() {
  let tl = gsap.timeline({ pause: true });
  let servicesText = splitText(".header__navigation a");

  tl.set(".header__navigation a", { overflow: "hidden" });

  gsap.set(".body", {
    overflow: "auto",
    pointerEvents: "auto",
    userSelect: "auto",
  });

  /* LOGO RESTART */

  tl.add(() => {
    const STATE = Flip.getState("#logo");
    document.querySelector(".header")?.classList.remove("loader");
    Flip.from(STATE, {
      duration: 1,
      ease: "power1.inOut",
    });
  });

  tl.set(".header__navigation", {
    position: "relative",
  });

  tl.set([".container", ".header__line", ".header__navigation"], {
    visibility: "visible",
  });

  /* HEADER ANIMATION & INDEX ANIMATION */

  tl.fromTo(
    ".header__line",
    { opacity: 0, width: "0%", duration: 1 },
    { opacity: 1, width: "100%", duration: 1 },
    ">+1"
  );

  tl.from(servicesText.words, {
    opacity: 0,
    stagger: 0.02,
    y: 100,
  });

  tl.to(
    ".video-container video",
    {
      opacity: 1,
      duration: 1,
      onComplete: () => {
        let video = document.querySelector(".video-container video") as any;
        video?.play();
      },
    },
    "<"
  );

  tl.fromTo(
    ".container__index #about",
    { opacity: 0, display: "grid" },
    { opacity: 1, duration: 1 },
    "<-0.5"
  );

  return tl;
}

export function LoadingAnimationExtend() {
  const tl = gsap.timeline({ paused: true });

  tl.set([".scroll-to #logo #Wild path", ".scroll-to #logo #Wildest path"], {
    opacity: "0",
  });

  tl.set(["#logo #Punto"], { cx: "560" });

  tl.to("#logo #Punto", {
    r: 25,
    ease: "power3.inOut",
    duration: 0.5,
  });

  tl.to(
    "#logo #Punto",
    {
      r: 16.83,
      ease: "power3.inOut",
      duration: 0.5,
    },
    ">"
  );

  tl.to(
    ".scroll-to #logo #Wildest path",
    {
      opacity: 1,
      stagger: 0.08,
      reversed: true,
    },
    ">"
  );

  tl.to(
    ".scroll-to #logo #Wild path",
    {
      opacity: 1,
      stagger: 0.08,
      reversed: false,
    },
    ">-0.4"
  );

  tl.to(
    [".scroll-to #logo #Wildest path", ".scroll-to #logo #Wild path"],
    {
      opacity: 0,
    },
    ">"
  );

  return tl;
}

export function headerAnimation() {
  const tl = gsap.timeline({ paused: true });
}

/*  SEE MORE ANIMATION GSAP (COMPONENT) */

export const AnimationSeeMore = () => {
  const configurationGSAP = {
    paused: true,
    ease: "cubic-bezier(0.65,0.05,0.36,1)",
  };

  const extendModel = (element: any, height: string) =>
    gsap.timeline(configurationGSAP).to(element, {
      height,
      duration: 0.8,
    });

  const minimizeModel = (element: any) =>
    gsap.timeline(configurationGSAP).to(element, {
      height: "0px",
      duration: 0.5,
    });

  const animation = {
    extendModel,
    minimizeModel,
  };

  return animation;
};

export const AnimationSeeMoreIndex = () => {
  const text = splitText(".about__midle p");

  const openAnimation = gsap
    .timeline({ paused: true, ease: "cubic-bezier(0.65,0.05,0.36,1)" })
    .fromTo(
      [".about__midle", ".about__down"],
      {
        opacity: 0,
        display: "none",
        y: 20,
      },
      {
        opacity: 1,
        display: "flex",
        y: 0,
      }
    )
    .from(
      text.words,
      {
        opacity: 0,
        stagger: 0.025,
        y: 30,
        onComplete: () => {
          text.revert();
        },
      },
      "<"
    );

  const closeAnimation = gsap
    .timeline({ paused: true, ease: "cubic-bezier(0.65,0.05,0.36,1)" })
    .fromTo(
      [".about__midle", ".about__down"],
      {
        opacity: 1,
        display: "flex",
        y: 0,
      },
      {
        opacity: 0,
        display: "none",
        y: 20,
      },
      "<"
    );

  const animation = {
    openAnimation,
    closeAnimation,
  };

  return animation;
};

export const AnimationSeeMoreNewest = () => {
  const openAnimation = gsap
    .timeline({ paused: true, ease: "cubic-bezier(0.65,0.05,0.36,1)" })
    .set(".newest__project", {
      display: "grid",
      pointerEvents: "none",
    })
    .set(".newest__project .project__item", {
      visibility: "hidden",
    })
    .fromTo(
      ".newest__project .project__item",
      {
        opacity: 0,
        y: 20,
        visibility: "hidden",
      },
      {
        visibility: "visible",
        opacity: 1,
        y: 0,
        stagger: 0.05,
        pointerEvents: "auto",
      },
      ">+0.5"
    );

  const closeAnimation = gsap
    .timeline({ paused: true, ease: "cubic-bezier(0.65,0.05,0.36,1)" })
    .fromTo(
      ".newest__project .project__item",
      {
        y: 0,
        stagger: 0.05,
        opacity: 1,
      },
      {
        y: 20,
        opacity: 0,
      },
      "<"
    );

  const animation = {
    openAnimation,
    closeAnimation,
  };

  return animation;
};

export const AnimationSeeMoreServices = () => {
  const openAnimation = gsap
    .timeline({ paused: true, ease: "cubic-bezier(0.65,0.05,0.36,1)" })
    .set([".container__services .seeMore__block"], {
      visibility: "hidden",
      opacity: 0,
    })
    .fromTo(
      [".container__services .seeMore__block"],
      {
        opacity: 0,
        y: 20,
      },
      {
        visibility: "visible",
        opacity: 1,
        y: 0,
        stagger: 0.05,
      },
      ">+0.5"
    );

  const closeAnimation = gsap
    .timeline({ paused: true, ease: "cubic-bezier(0.65,0.05,0.36,1)" })
    .fromTo(
      [".container__services .seeMore__block"],
      {
        y: 0,
        stagger: 0.05,
        opacity: 1,
      },
      {
        y: 20,
        opacity: 0,
      },
      ">+0.5"
    );

  const animation = {
    openAnimation,
    closeAnimation,
  };

  return animation;
};

export default {
  AnimationSeeMore,
  AnimationSeeMoreIndex,
  AnimationSeeMoreNewest,
  AnimationSeeMoreServices,
  LoadingAnimationFLIP,
  LoaderAnimationFLIP,
};
