const STRAPI_URL = process.env.NEXT_PUBLIC_STRAPI_URL;
const HOST_NAME = process.env.NEXT_PUBLIC_HOST_NAME;
const HOST_NAME_URL = process.env.NEXT_PUBLIC_SHOPIFY_REDIRECT_URL;
const SHOPIFY_API_KEY = process.env.NEXT_PUBLIC_SHOPIFY_API_KEY;
const SHOPIFY_API_SECRET = process.env.NEXT_PUBLIC_SHOPIFY_API_SECRET;
const SHOPIFY_TOKEN = process.env.NEXT_PUBLIC_SHOPIFY_TOKEN;

export {
  STRAPI_URL,
  HOST_NAME,
  HOST_NAME_URL,
  SHOPIFY_API_KEY,
  SHOPIFY_API_SECRET,
  SHOPIFY_TOKEN,
};
