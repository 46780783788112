export default function Logo() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="logo"
        viewBox="0 0 594.11 559.56"
        fill="currentColor"
        width={60}
        height={60}
      >
        <g>
          <g>
            <g id="Wild">
              <path
                id="W"
                d="m327.21,492.86l-26.92,5.23-33.16-73.83-3.09,80.88-27.46,5.34-54.14-113.72,31.05-6.03,31.11,74.79,2.63-81.35,23.51-4.57,32.8,74.84,1.12-81.43,31.05-6.04-8.5,125.89Z"
              ></path>
              <path
                d="m407.14,454.99l-65.43-102.9,26.23-16.68,65.43,102.91-26.23,16.68Z"
                id="I"
              ></path>
              <path
                id="L"
                d="m476.95,404.45l-88.75-83.64,21.32-22.62,68.79,64.83,24.7-26.21,19.96,18.81-46.02,48.83Z"
              ></path>
              <path
                id="D"
                d="m573.97,265.85l-18.57,44.16-112.42-47.27,15.87-37.76c5.53-13.15,10.98-23.75,22.97-31.99,17.64-12.22,38.55-11.96,57.76-3.88,32.19,13.54,48.48,43.19,34.38,76.74Zm-88.42-33.61l-5.24,12.47,61.86,26.01,5.17-12.3c8.08-19.21-.75-32.65-18.96-40.3-17.87-7.51-34.68-5.26-42.83,14.12Z"
              ></path>
            </g>
            <g id="Wildest">
              <path
                id="W"
                d="m126.16,407.87l7.28,26.44-71.08,38.71,80.87-3.1,7.42,26.97-109.23,62.68-8.39-30.5,72.19-36.74-81.31,3.6-6.35-23.09,72.1-38.43-81.27,5.11-8.39-30.5,126.16-1.16Z"
              ></path>
              <path
                id="I"
                d="m129.48,360.94l-120.68-17.52,4.47-30.76,120.68,17.52-4.47,30.76Z"
              ></path>
              <path
                id="L"
                d="m139.63,303.7l-111.21-50.04,12.75-28.34,86.2,38.79,14.78-32.84,25.01,11.25-27.54,61.19Z"
              ></path>
              <path
                id="D"
                d="m209.8,195.18l-35.03,32.67-83.18-89.19,29.95-27.93c10.43-9.73,19.75-17.16,34.07-19.77,21.09-3.92,40.06,4.89,54.28,20.13,23.82,25.54,26.52,59.27-.09,84.09Zm-66.88-66.89l-9.89,9.23,45.77,49.07,9.76-9.1c15.24-14.22,12.69-30.09-.78-44.53-13.22-14.17-29.48-19.01-44.86-4.67Z"
              ></path>
              <path
                id="E
             "
                d="m270.39,150.05l-40.82-114.92,63.75-22.64,9.18,25.84-35.67,12.67,6.3,17.75,34.63-12.3,9.18,25.84-34.63,12.3,6.98,19.64,35.67-12.67,9.18,25.84-63.75,22.65Z"
              ></path>
              <path
                id="S"
                d="m392.52,126.68c-24.31-.48-43.99-13.31-43.47-39.45l.08-4.02,31.26.62c-.16,7.86,3.15,16.52,12.29,16.7,6.58.13,12.16-4.7,12.3-11.46.15-7.31-7.1-10.93-12.72-13.42-9.79-4.4-20.14-7.9-28.42-15.01-7.37-6.36-11.94-15.78-11.75-25.47C352.54,12.33,374.74-.4,395.58,0c12.07.24,24.96,4.88,32.63,14.54,6.43,7.99,7.4,14.04,7.75,24.11l-30.89-.61c-.78-6.96-3.42-11.77-10.92-11.92-5.12-.1-10.86,3.44-10.97,9.11-.13,6.4,5.11,9.79,10.18,12.27,19.74,9.53,43.01,16.76,42.5,42.72-.51,25.78-19.38,36.92-43.33,36.45Z"
              ></path>
              <path
                id="T"
                d="m531.62,59.87l-34.55,87.99-28.93-11.36,34.55-87.99-22.47-8.82,10.02-25.53,73.86,29-10.02,25.53-22.46-8.82Z"
              ></path>
            </g>
            <circle id="Punto" cx="577.28" cy="138.42" r="16.83"></circle>
          </g>
        </g>
      </svg>
    </>
  );
}
