"use client";
import Link from "next/link";
import { useState } from "react";
import socialMediaInfo from "@/pages/api/data";
import NewsletterModal from "@/components/util/Modal";
import ScrollTo from "@/components/icon/scrollTo";
// hooks
import useMediaQuery from "@/hook/mediaQuery";
import MobileFooter from "./mobileFooter";

const Footer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile: boolean = useMediaQuery("(max-width: 768px)");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isMobile ? (
        <MobileFooter />
      ) : (
        <div className="footer">
          <div className="footer__info">
            <p>
              We're a digital creative agency that's all about brand strategy,
              art direction, design, and making cool stuff across all platforms,
              we want you to be 100% online!
            </p>
            <button className="btn-default" onClick={handleOpenModal}>
              Join our newsletter
            </button>
            <NewsletterModal isOpen={isModalOpen} onClose={handleCloseModal} />
            <div className="footer__links">
              <Link href="/terms/cookies-settings">Cookies settings</Link>
              <Link href="/terms/privacy-and-cookies-policy">
                Privacy and cookies policy
              </Link>
              <Link href="/terms/terms-of-use">Terms of use</Link>
            </div>
          </div>
          <ScrollTo />
          <div className="footer__down">
            <div className="social">
              {socialMediaInfo.map((social) => (
                <a key={social.name} href={social.url} target="_blank">
                  {social.name}
                </a>
              ))}
              {/* <a href="#">Back up</a> */}
            </div>
            <a href="#" className="btn-default" onClick={handleOpenModal}>
              Contact us by email
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
